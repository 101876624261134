<script>
import { required } from 'vuelidate/lib/validators'
import ConfirmationModal from '@/components/general/ConfirmationModal'
import ModalPositionDetails from '../positions/ModalPositionDetails.vue'
import TextArea from '@/components/general/TextArea'

export default {
  components: {
    ConfirmationModal,
    ModalPositionDetails,
    TextArea
  },
  name: 'CandidatureListItem',
  props: {
    candidature: {
      type: Object,
      required
    },
    forceStatus: {
      type: String,
      default: null
    }
  },
  computed: {
    registrationClosed () {
      return !this.candidature.position.allowNewApplications && !this.candidature.position.finishedAt
    }
  },
  data () {
    return {
      showModal: false,
      showConfirmationModal: false,
      showAllowNewApplicationsModal: false,
      handleUnreadMessages: [],
      giveUpReason: null
    }
  },
  validations: {
    giveUpReason: {
      required
    }
  },
  methods: {
    getCandidatureStatus () {
      return this.forceStatus || this.candidature.position.status
    },
    redirectToPosition (url) {
      window.location.href = (url)
    },
    validateAction () {
      this.$emit('refreshGiveUp')
      this.showConfirmationModal = false
      this.$emit('confirmReject', { id: this.candidature.position.id, reason: this.giveUpReason })
    },
    validateFunctionButton () {
      if (!this.candidature.position.allowNewApplications && this.getCandidatureStatus() !== 'no_filter') {
        this.showAllowNewApplicationsModal = true
      } else if (this.getCandidatureStatus() !== 'no_filter') {
        const position = this.candidature.position
        const enliztme = process.env.VUE_APP_ENLIZTME_DOMAIN + (process.env.VUE_APP_ENLIZTME_PORT ? (':' + process.env.VUE_APP_ENLIZTME_PORT) : '')
        const isbra = position.company.language === 'pt'
        const form = document.createElement('form')
        form.target = 'enliztme'
        form.method = 'POST'
        form.action = `${process.env.VUE_APP_ENLIZTME_PROTOCOL}://${position.company.subdomain}.${enliztme}/${isbra ? 'vagas' : 'positions'}/${position.id}/${isbra ? 'candidatar' : 'apply'}?n=${encodeURI(position.title)}`
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'token'
        input.value = this.$store.getters.getUserToken
        form.appendChild(input)
        document.body.appendChild(form)
        form.submit()
        form.parentNode.removeChild(form)
      } else {
        this.$emit('handleModalMessageCandidature')
      }
    }
  }
}
</script>
<template>
  <div class="candidature-item--container" :class="[getCandidatureStatus()]">
    <ModalPositionDetails
      v-if="showModal"
      @close="showModal = !showModal"
      :id="candidature.position.id"
      :title="candidature.position.title"
      :companyName="candidature.position.company.name"
      :companyLogo="candidature.position.company.logo"
      :location="location"
      :salary="salary"
      :numberOfCourses="numberOfCourses"
      :urlMe="url"
      :isFavorite="isFavorite"
    />
    <div class="candidature-item--left-wrapper">
      <v-img :src="candidature.position.company.logo" height="44px" width="44px"></v-img>
      <div class="candidature-item--info-wrapper">
        <p class="lato bold">{{ candidature.position.title }}</p>
        <p class="lato">{{ candidature.position.company.name }}</p>
      </div>
    </div>
    <div class="candidature-item--right-wrapper">
      <span class="candidature-item--status" v-if="registrationClosed">
        <v-icon size="14">mdi-alert-circle</v-icon>
        {{  $t('global:status.position') }}
      </span>
      <span class="candidature-item--status" v-if="!registrationClosed && (forceStatus !== 'closed' || $te(`global:position.status.${candidature.position.status}`))">
        <v-icon size="14">{{ getCandidatureStatus() !== 'no_filter' ? 'mdi-list-status' : 'mdi-clock-outline' }}</v-icon>
        {{ getCandidatureStatus() !== 'no_filter' ? $tc('global:steps.left', candidature.stepsLeft, { n: candidature.stepsLeft }) : $t(`applications.status:${candidature.position.status}`) }}
      </span>
      <div class="candidature-item--actions">
        <v-btn v-if="forceStatus !== 'closed' &&  getCandidatureStatus() !== 'no_filter'" class="bold" outlined :color="isAggregator ? getPrimaryColor : '#1200D3'" small @click="showConfirmationModal = true">
          {{ $t('global:give.up') }}
        </v-btn>
        <v-btn v-if="forceStatus !== 'closed' &&  getCandidatureStatus() !== 'no_filter'" class="bold" outlined :color="isAggregator ? getPrimaryColor : '#1200D3'"  @click.prevent="showModal = !showModal" :href="`${getWorkspaceRoot()}/discovery/position/${candidature.position.id}`" small>
          {{ $t('global:show.position') }}
        </v-btn>
        <v-btn v-if="forceStatus !== 'closed'" class="bold" :color="isAggregator ? getPrimaryColor : '#1200D3'" dark small @click="validateFunctionButton()">
          <v-icon size="14" class="mr-2">{{ getCandidatureStatus() !== 'no_filter' ? 'mdi-open-in-new' : 'mdi-message' }}</v-icon>
          {{ $t(getCandidatureStatus() !== 'no_filter' ? 'global:continue' : 'global:messages') }}
          <span class="pending-message--amount" v-if="getCandidatureStatus() === 'no_filter' && candidature.position.messagesCounter > 0">{{ candidature.position.messagesCounter }}</span>
        </v-btn>
        <v-btn v-if="forceStatus === 'closed'" class="bold history" outlined :color="isAggregator ? getPrimaryColor : '#1200D3'" dark small @click="$emit('handleModalMessageCandidatureHistory')">
          <v-icon size="14" class="mr-2">mdi-forum</v-icon>
          {{ $t('global:history') }}
        </v-btn>
      </div>
    </div>
    <confirmation-modal
      :show="showConfirmationModal"
      :title="$t('candidature.progress.reject.modal:title')"
      :description="$t('candidature.progress.reject.modal:info')"
      :confirmText="$t('global:give.up')"
      :btnConfirmText="false"
      @confirm="validateAction()"
      :cancelText="$t('global:cancel')"
      @cancel="showConfirmationModal = false"
    >
      <text-area v-model.trim="giveUpReason" outlined :placeholder="$t('candidature.item:give.up.reason')"></text-area>
    </confirmation-modal>

    <confirmation-modal
        :show="showAllowNewApplicationsModal"
        :title="$t('candidature.progress.application.modal:title')"
        :description="$t('candidature.progress.application.modal:info')"
        :confirmText="$t('global:ok')"
        :btnConfirmText="false"
        @confirm="showAllowNewApplicationsModal = false"
        @cancel="showAllowNewApplicationsModal = false"
      />
  </div>
</template>
<style lang="scss">
.candidature-item--container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 16px;
  text-align: left;
  background-color: white;
  &:not(.no_filter) {
    &:before {
      background-color: #FF8888;
    }
    .candidature-item--right-wrapper {
      .candidature-item--status {
        background-color: rgba(255, 136, 136, 0.1);
        color: rgba(255, 136, 136, 1);
        .v-icon {
          color: rgba(255, 136, 136, 1);
        }
      }
    }
  }
  &.closed {
    background: rgba(0, 0, 0, .06);
    &:before {
      background-color: unset;
    }
    .candidature-item--left-wrapper {
      opacity: .5;
    }
  }
  &.no_filter {
    &:before {
      background-color: unset;
    }
    .candidature-item--right-wrapper {
      .candidature-item--status {
        background-color: rgba(255, 136, 136, 0.1);
        color: rgba(255, 136, 136, 1);
        .v-icon {
          color: rgba(255, 136, 136, 1);
        }
      }
    }
  }
  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4px 0 0 4px;
  }
  .candidature-item--left-wrapper {
    display: flex;
    .v-image {
      border-radius: 100px;
    }
    .candidature-item--info-wrapper {
      margin-left: 8px;
       p {
         margin-bottom: 0;
         &.bold {
           font-family: $lato;
           font-style: normal;
           font-size: 14px;
           color: #000;
         }
         &:nth-child(2) {
           font-family: $lato;
           font-weight: 400;
           font-size: 14px;
           line-height: 17px;
         }
       }
    }
  }
  .candidature-item--right-wrapper {
    display: flex;
    align-items: center;
    .v-btn {
      text-transform: unset;
    }
    .candidature-item--status {
      margin: 0 16px;
      padding: 4px 8px;
      max-height: 22px;
      border-radius: 100px;
      font-family: $lato;
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, .1);
      color: rgba(0, 0, 0, .8);
      white-space: nowrap;
      .v-icon {
        margin-right: 4px;
        color: rgba(0, 0, 0, .8);
      }
    }
    .candidature-item--actions {
      display: inherit;
      .v-btn {
        margin-left: 8px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    .candidature-item--right-wrapper {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 8px;
      .candidature-item--status {
        margin-left: 0;
      }
      .candidature-item--actions {
        margin-top: 8px;
        width: 100%;
        display: flex;
        .v-btn {
          margin-bottom: 8px;
          &.history {
            width: 100%;
          }
        }
      }
    }
  }
  .pending-message--amount {
    position: absolute;
    right: -15px;
    top: -7px;
    height: 20px;
    width: 20px;
    transform: translate(25%, -25%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(214, 95, 87, 0.2), rgba(214, 95, 87, 0.2)), #FFFFFF;
    border: 1px solid #D65F57;
    box-shadow: 0px 1px 3px rgba(255, 49, 35, 0.4);
    border-radius: 100px;
    color: #D65F57;
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
  }
  @media only screen and (max-width: 400px) {
    .candidature-item--right-wrapper {
      width: 100%;
      .candidature-item--actions {
        flex-direction: column;
        .v-btn {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
